import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import * as Sentry from '@sentry/angular';

if ( environment.production )
{
    enableProdMode();
}

Sentry.init({
    environment: environment.production ? 'production' : 'development',
    release: environment.sentryRelease,
    dsn: environment.sentryDsn,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        }),
        Sentry.captureConsoleIntegration()
    ],
    tracePropagationTargets: ['localhost', /^https:\/\/api.dreamagility-coreservices.co.uk\/api/],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    autoSessionTracking: true,
    debug: false
});

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
