/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    //#region Home
    {
        id      : 'home',
        title   : 'Home',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id: 'home.home',
                title: 'Home',
                type: 'basic',
                link: '/home'
            }
        ]
    },
    //#endregion
    //#region Accounts
    {
        id      : 'accounts',
        title   : 'Accounts',
        type    : 'group',
        icon    : 'heroicons_outline:office-building',
        children: [
            {
                id: 'accounts.accounts',
                title: 'Accounts',
                type: 'basic',
                link: '/accounts'
            },
            // {
            //     id: 'accounts.add_account',
            //     title: 'Add Account',
            //     type: 'basic',
            //     link: '/accounts/add'
            // }
        ]
    },
    //#endregion
    //#region Dashboards
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:presentation-chart-line',
        children: [
            {
                id: 'dashboards.top_products_dashboard',
                title: 'Top Products Dashboard',
                type: 'basic',
                link: '/top_products_dashboard'
            }
        ]
    },
    //#endregion
    //#region Feed Manager
    {
        id      : 'feed_manager',
        title   : 'Feed Manager',
        type    : 'group',
        icon    : 'heroicons_outline:newspaper',
        children: [
            {
                id: 'feed_manager.feeds',
                title: 'Feeds',
                type: 'basic',
                link: '/feeds'
            },
            {
                id: 'feed_manager.feed_optimiser',
                title: 'Feed Optimiser',
                type: 'basic',
                link: '/feed_optimiser'
            },

            {
                id: 'feed_manager.product_search',
                title: 'Product Search',
                type: 'basic',
                link: '/product_search'
            },
            {
                id:'feed_product_exclusions',
                title:'Feed Product Exclusions',
                type:'basic',
                link:'/feed_product_exclusions'
            },
            // {
            //     id:'feed_manager.sdk_scripts',
            //     title: 'Feed SDK Scripts',
            //     type: 'basic',
            //     link: '/feed_sdk_scripts'
            // }
            {
                id:'feed_manager.batch_updates',
                title: 'Product Batch Updates',
                type: 'basic',
                link: '/batch_updates'
            }
        ]
    },
    //#endregion    
    //#region Tools
    {
        id      : 'tools',
        title   : 'Tools',
        type    : 'group',
        icon    : 'heroicons_outline:cog',
        children: [
            {
                id: 'tools.mlod',
                title: 'MLoD',
                type: 'basic',
                link: '/mlod',
            },
            {
                id: 'tools.reverse_mlod',
                title: 'Reverse MLoD',
                type: 'basic',
                link: '/reverse_mlod'
            },
            {
                id: 'tools.product_optimiser',
                title: 'Product Optimiser',
                type: 'basic',
                link: '/product_optimiser',
                children: [{
                    id: 'tools.product_optimiser.excluded_products',
                    title: 'Excluded Products',
                    type: 'basic',
                    link: '/product_optimiser/excluded_products'
                }
            ]
            },
            {
                id: 'tools.structure_builder',
                title: 'Structure Builder',
                type: 'basic',
                link: '/structure_builder'
            },
            {
                id: 'tools.bidding_genius',
                title: 'Bidding Genius',
                type: 'basic',
                link: '/bidding_genius'
            },
            {
                id: 'tools.tracking_pixel',
                title: 'Tracking Pixel',
                type: 'collapsable',
                //link: '/tracking_pixel'
                children: [
                    {
                        id      : 'tools.tracking_pixel.leadsview',
                        title   : 'Leads View',
                        type    : 'collapsable',
                        children: [
                            {
                                id   : 'tools.tracking_pixel.leadsview.leads',
                                title: 'Leads',
                                type : 'basic',
                                link : '/tracking_pixel/leads'
                            },
                            {
                                id   : 'tools.tracking_pixel.leadsview.leadsconversions',
                                title: 'Lead Conversions',
                                type : 'basic',
                                link : '/tracking_pixel/lead_conversions'
                            }
                        ],
                    },
                     {
                        id      : 'tools.tracking_pixel.referencecodesview',
                        title   : 'Reference Codes View',
                        type    : 'collapsable',
                        children: [
                            {
                                id   : 'tools.tracking_pixel.referencecodesview.refcodes',
                                title: 'Reference Codes',
                                type : 'basic',
                                link : 'tracking_pixel/reference_codes'
                            },
                            {
                                id   : 'tools.tracking_pixel.referencecodesview.offlineleads',
                                title: 'Offline Leads',
                                type : 'basic',
                                link : '/tracking_pixel/offline_leads'
                            },
                             {
                                id   : 'tools.tracking_pixel.referencecodesview.conversions',
                                title: 'Conversions',
                                type : 'basic',
                                link : '/tracking_pixel/reference_codes_conversions'
                            },
                            // {
                            //     id   : 'tools.tracking_pixel.referencecodesview.returns',
                            //     title: 'Returns',
                            //     type : 'basic',
                            //     link : '/tracking_pixel/returns'
                            // }
                        ],
                    },
                    {
                        id      : 'tools.tracking_pixel.bookingmanager',
                        title   : 'Booking Manager',
                        type    : 'collapsable',
                        children: [
                            {
                                id   : 'tools.tracking_pixel.bookingmanager.manageschedule',
                                title: 'Manage Schedule',
                                type : 'basic',
                                link : '/tracking_pixel/bookings/schedule'
                            },
                        ],
                    },
                ],
                //allowedRoles: ['Admin','Manager','User'],
            }
        ]
    },
    //#endregion
    //#region Scheduler
    {
        id      : 'scheduler',
        title   : 'Scheduler',
        tooltip : 'Scheduler',
        type    : 'group',
        icon    : 'heroicons_outline:calendar',
        children: [
            // {
            //     id: 'scheduler.feed_scheduler',
            //     title: 'Feed Scheduler',
            //     type: 'basic',
            //     link: '/feed_scheduler'
            // },
            // {
            //     id: 'scheduler.text_ads_scheduler',
            //     title: 'Texts Ads Update Scheduler',
            //     type: 'basic',
            //     link: '/text_ads_scheduler'
            // },
            {
                id: 'scheduler.job_scheduler',
                title: 'Job Scheduler',
                type: 'basic',
                link: '/job_scheduler'
            },
            {
                id: 'scheduler.scheduler_timeline',
                title: 'Timeline',
                type: 'basic',
                link: '/scheduler_timeline'
            },
        ] 
    },
    //#endregion
    //#region Logs
    {
        id      : 'logs',
        title   : 'Logs',
        tooltip : 'Logs',
        type    : 'group',
        icon    : 'heroicons_outline:bookmark-alt',
        children: [
            {
                id: 'logs.mlod',
                title: 'MLoD logs',
                type: 'basic',
                link: '/logs/mlod_logs'
            },
            {
                id: 'logs.reverse_mlod',
                title: 'Reverse MLoD Logs',
                type: 'basic',
                link: '/logs/reverse_mlod_logs'
            },
            {
                id: 'logs.product_optimiser',
                title: 'Product Optimiser Logs',
                type: 'basic',
                link: '/logs/product_optimiser_logs'
            },
            {
                id: 'logs.bidding_genius',
                title: 'Bidding Genius Logs',
                type: 'basic',
                link: '/logs/bidding_genius_logs'
            },
            {
                id: 'logs.structure_builder',
                title: 'Structure Builder Logs',
                type: 'basic',
                link: '/logs/structure_builder_logs'
            },
            {
                id: 'logs.feed',
                title: 'Feed Logs',
                type    : 'collapsable',
                        children: [
                            {
                                id   : 'logs.feeds.download',
                                title: 'Download',
                                type : 'basic',
                                link : '/logs/feed_logs/download'
                            },
                            {
                                id   : 'logs.feeds.publish',
                                title: 'Publish',
                                type : 'basic',
                                link : '/logs/feed_logs/publish'
                            },
                            {
                                id   : 'logs.feeds.feed_download_publish',
                                title: 'Download/Publish',
                                type : 'basic',
                                link : '/logs/feed_logs/feed_download_publish'
                            }
                        ]
            },
            // {
            //     id: 'logs.top_products_dashboard_logs',
            //     title: 'Top Products Dashboard Logs',
            //     type: 'basic',
            //     link: '/logs/top_products_dashboard_logs'
            // },
            {
                id: 'logs.user',
                title: 'User Logs',
                type: 'basic',
                link: '/logs/user_logs'
            },
        ] 
    },
    //#endregion
    //#region Users
    {
        id      : 'users',
        title   : 'Users',
        tooltip : 'Users',
        type    : 'group',
        icon    : 'heroicons_outline:users',
        children: [
            {
                id: 'users.basic',
                title: 'Users',
                type: 'basic',
                link: '/users'
            }
        ]
    }
    //#endregion

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'home',
        title   : 'Home',
        tooltip : 'Home',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
    },    
    {
        id      : 'accounts',
        title   : 'Accounts',
        tooltip : 'Accounts',
        type    : 'aside',
        icon    : 'heroicons_outline:office-building',
        //allowedRoles: ['Admin','Manager','Operator','User'],
    },
    // {
    //     id      : 'dashboards',
    //     title   : 'Dashboards',
    //     tooltip : 'Dashboards',
    //     type    : 'aside',
    //     icon    : 'heroicons_outline:presentation-chart-line',
    // },
    {
        id      : 'feed_manager',
        title   : 'Feed Manager',
        tooltip : 'Feed Manager',
        type    : 'aside',
        icon    : 'heroicons_outline:newspaper',
        //allowedRoles: ['Admin','Manager'],
    },
    {
        id      : 'tools',
        title   : 'Tools',
        tooltip : 'Tools',
        type    : 'aside',
        icon    : 'heroicons_outline:cog',
        //allowedRoles: ['Admin','Manager','User'],
    },
    {
        id      : 'scheduler',
        title   : 'Scheduler',
        tooltip : 'Scheduler',
        type    : 'aside',
        icon    : 'heroicons_outline:calendar',
        //allowedRoles: ['Admin','Manager'],
    },
    {
        id      : 'logs',
        title   : 'Logs',
        tooltip : 'Logs',
        type    : 'aside',
        icon    : 'heroicons_outline:bookmark-alt',
        //allowedRoles: ['Admin','Manager','User','Operator'],
    },
    {
        id      : 'users',
        title   : 'Users',
        tooltip : 'Users',
        type    : 'aside',
        icon    : 'heroicons_outline:users',
        //allowedRoles: ['Admin'],
    }

];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
