import {Injectable} from '@angular/core';
import {JobType} from 'app/core/enums/job/job.type';
import {FrontendApi} from '../frontend.api';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    constructor() {

    }

    countArrLen(myArr: any): number {
        return myArr.filter( i => typeof(i) === 'object').length;
    }

    formatConfidenceThreshold(confidenceThreshold: number): string {
        if (confidenceThreshold) {
            const confidenceThresholdPercent = confidenceThreshold * 100;
            return confidenceThresholdPercent.toFixed(1) + '%';
        }
        return 'N/A';
    }

    formatCurrency(value: number): string {
        if (value) {
            return 'US$' + value.toFixed(2);
        }
        return 'N/A';
    }

    formatDecimal(value: number): string {
        if (value) {
            return value.toFixed(2);
        }
        return 'N/A';
    }

    formatConfidenceThresholdRequest(value: number): string {
        if (value) {
            return value + '%';
        }
        return 'N/A';
    }

    setDefaultAccount(accountId): void {
        localStorage.setItem('accountId', accountId);
    }

    getDefaultAccount(): string {
        return localStorage.getItem('accountId') ?? '';
    }

    getDuration(startDate, endDate): string {
        if (startDate && endDate) {
            const startTime = new Date(startDate).getTime();
            const endTime = new Date(endDate).getTime();

            if (endTime > startTime) {
                const duration = endTime - startTime;
                const minutes = Math.floor(duration / 60000);
                const seconds = ((duration % 60000) / 1000).toFixed(0);
                const value = (Number(minutes) < 10 ? '0' : '') + minutes + ':' + (Number(seconds) < 10 ? '0' : '') + seconds;
                return value;
            }
            else {
                return 'N/A';
            }
        }
        else {
            return 'N/A';
        }
    }

    getJobTypeByPath(path: string): JobType {
        let jobType: JobType;
        switch (path) {
            case 'mlod_logs':
                jobType = JobType.Mlod;
                break;
            case 'reverse_mlod_logs':
                jobType = JobType.ReverseMlod;
                break;
            case 'product_optimiser_logs':
                jobType = JobType.ProductOptimiser;
                break;
            case 'bidding_genius_logs':
                jobType = JobType.BiddingGenius;
                break;
            case 'structure_builder_logs':
                jobType = JobType.StructureBuilder;
                break;
            case 'top_products_dashboard_logs':
                jobType = JobType.TopProductIdentifier;
                break;
            case 'user_logs':
                jobType = null; //No Job type.
                break;
            case 'feed_logs':
                jobType = JobType.FeedDownloadPublish;
                break;
            case 'feed_logs/download':
                jobType = JobType.Download;
                break;
            case 'feed_logs/publish':
                jobType = JobType.Publish;
                break;
            case 'feed_logs/feed_download_publish':
                jobType = JobType.FeedDownloadPublish;
                break;
            default:
                throw new Error('Invalid Path');
        }
        return jobType;
    }

    getLogNameByPath(path: string): string {
        let logName: string;
        switch (path) {
            case 'mlod_logs':
                logName = 'MLoD';
                break;
            case 'reverse_mlod_logs':
                logName = 'Reverse MLoD';
                break;
            case 'product_optimiser_logs':
                logName = 'Product Optimiser';
                break;
            case 'bidding_genius_logs':
                logName = 'Bidding Genius';
                break;
            case 'structure_builder_logs':
                logName = 'Structure Builder';
                break;
            case 'top_products_dashboard_logs':
                logName = 'Top Products Dashboard';
                break;
            case 'user_logs':
                logName = 'Users';
                break;
            case 'feed_logs':
                logName = 'Feed';
                break;
            case 'feed_logs/download':
                logName = 'Download';
                break;
            case 'feed_logs/publish':
                logName = 'Publish';
                break;
            case 'feed_logs/feed_download_publish':
                logName = 'Download/Publish';
                break;
            default:
                throw new Error('Invalid Path');
        }
        return logName;
    }

    getApiUrlByJobType(jobType: JobType): string {
        let urlExt: string;
        switch(jobType){
            case JobType.BiddingGenius:
                urlExt = FrontendApi.biddingGenius;
                break;
            case JobType.Mlod:
                urlExt = FrontendApi.mlod;
                break;
            case JobType.ProductOptimiser:
                urlExt = FrontendApi.productOptimiser;
                break;
            case JobType.ReverseMlod:
                urlExt = FrontendApi.reverseMlod;
                break;
            case JobType.StructureBuilder:
                urlExt = FrontendApi.structureBuilder;
                break;
            case JobType.TopProductIdentifier:
                break;
            case JobType.Download:
                urlExt = FrontendApi.feed;
                break;
            case JobType.Publish:
                urlExt = FrontendApi.feed;
                break;
            case JobType.FeedDownloadPublish:
                urlExt = FrontendApi.feed;
                break;
        }
        return urlExt;
    }

    parseJson(json: any): any {
        const obj = JSON.parse(json);
        return  obj;
    }

    parseError(err: any): string {
        let message = 'Unknown Error Occurred';
        if(err.error)
        {
          if(err.error.title)
          {
            message = err.error.title;
          }
          else if(err.error.message)
          {
            message = err.error.message;
          }
        }
        return message;
    }

    parseValue(value: any): string {
        if (value) {
            return value;
        }
        return 'N/A';
    }

    sanitiseAccountName(accountName: string): string {
        const sanitisedAccountName = accountName.replace(/[^\w\s]/gi, '').replace(/[\s-]/g, '');
        return sanitisedAccountName;
    }

    formatDate(date): string {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    truncateText(text: string, length: number): string {
        return text ? (text.length > length ? (text.slice(0, length) + '...') : text) : '-';
    }

    isAdmin(token: string): boolean {
        const role: string = AuthUtils.getRoleFromToken(token);

        if(role === 'Admin'){
            return true;
          }
          else{
            return false;
          }
    }

    isAdminOrManager(token: string): boolean {
        const role: string = AuthUtils.getRoleFromToken(token);
        return role === 'Admin' || role === 'Manager';
    }

}
